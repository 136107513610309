.App {
  -text-align: center;
  margin: 0;
  height: 100vh;
  width: 100vw;
  background-color: #dad5d2;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.Top {
  height: 10%;
  background-color: #ffffff;
  width: 94%;
  margin-left: 3%;
  text-align: left;
}

/* .Title  {
  height:5% ;
  background-color: #ffffff62;
  width: 94%;
  margin-left: 3%;
  text-align: left; 
}*/
.LeftSide {
  height: 84%;
  background-color: #ffffff;
  width: 15%;
  float: left;
  -margin-left: 3%;
  -border-width: 1px;
  -border-style: outset;
}
.RightSide {
  height: 84%;
  background-color: #ffffff;
  width: 15%;
  float: right;
  -margin-right: 3%;
}
.Rightdiff_1 {
  height: 84%;
  float: right;
  width: 1%;
  background-color: #ffffff;
  margin-right: 3%;
}
.Rightdiff_2 {
  height: 84%;
  float: right;
  width: 1%;
  background-color: #ffffff;
}
.Leftdiff_1 {
  height: 84%;
  float: left;
  width: 1%;
  background-color: #ffffff;
  margin-left: 3%;
}
.Leftdiff_2 {
  height: 84%;
  float: left;
  width: 1%;
  background-color: #ffffff;
}
.Map_content {
  height: 84%;
  background-color: #0f85c9;
  width: 59.9%;
  -float: center;
  -text-align: center;
  margin-left: 20.05%;
  margin-right: 20.05%;
}
.Footer {
  clear: both;
  height: 6vh;
  width: 100vw;
  text-align: center;
  line-height: 30px;
  -padding: 3px;
  background-color: rgb(50, 54, 53);
  color: white;
}
.Header {
  height: 8vh;
  background-color: #ffd4d4;
  text-align: center;
  line-height: 80%;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.test {
  background-color: #282c34;
  width: 100%; /*設定寬度為100%*/
  height: 300px; /*設定高度為300px*/
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
