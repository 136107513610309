.wrapper {
  display: flex;
  position: absolute;
}

.ctaphone {
  display: flex;
  padding: 5px 10px;
  font-weight: 900;

  text-decoration: none;
  font-family: "Noto Sans TC", sans-serif;
  font-size: 16px;
  color: white;
  background: #ff7348;
  transition: 1s;
  box-shadow: 6px 6px 0 black;
  transform: skewX(-15deg);
}

.ctaphone:focus {
  outline: none;
}

.ctaphone:hover {
  transition: 0.5s;
  box-shadow: 10px 10px 0 #fbc638;
}

.ctaphone span:nth-child(2) {
  transition: 0.5s;
  margin-right: 0px;
}

.ctaphone:hover span:nth-child(2) {
  transition: 0.5s;
  margin-right: 20px;
}

.ctaphone span {
  transform: skewX(15deg);
}

.ctaphone span:nth-child(2) {
  width: 30px;
  margin-left: 1px;
  position: relative;
  top: 12%;
}

/**************SVG****************/

path.one {
  transition: 0.4s;
  transform: translateX(-60%);
}

path.two {
  transition: 0.5s;
  transform: translateX(-30%);
}

.ctaphone:hover path.three {
  animation: color_anim 1s infinite 0.2s;
}

.ctaphone:hover path.one {
  transform: translateX(0%);
  animation: color_anim 1s infinite 0.6s;
}

.ctaphone:hover path.two {
  transform: translateX(0%);
  animation: color_anim 1s infinite 0.4s;
}

/* SVG animations */

@keyframes color_anim {
  0% {
    fill: white;
  }
  50% {
    fill: #fbc638;
  }
  100% {
    fill: white;
  }
}
