.top {
  height: 9vh;
  width: 100vh;
}
.leftNewDataFrom {
  float: left;
  width: 20vw;
  margin-left: 5vw;
}

.tagSelect {
  float: left;
  width: 20vw;
}
.categorySelect {
  float: left;
  width: 20vw;
}
