.react-datepicker__tether-element-attached-right .react-datepicker__triangle{
    left: auto;
    display: inline-block;
    right: 50px;
  }
  .react-datepicker__tab-loop{
    display: inline-block;
  }
  .react-datepicker__month-container {
    float: none;
    display: inline-block;
}